@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300@400;500;700&display=swap"); */

:root {
  --primaryClr: #ffbd00;
  --secondaryClr: #222a34;
  --headClr: #101520;
  --bodyClr: #1a1e29;
  --whiteClr: #ffffff;
  --blackClr: #000000;
  --lightClr: rgba(255, 255, 255, 0.6);
  --siteRedClr: #af2f2f;
}
.light:root {
  --primaryClr: #ffbd00;
  --secondaryClr: #999;
  --headClr: rgba(0, 0, 0, 0.175);
  --bodyClr: #ddd;
  --whiteClr: #000000;
  --blackClr: #ffffff;
}

img {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 767.98px) {
  .casinoProvidersGames ul li {
    width: calc(50% - 6.66px) !important;
  }
  .MaintainSection {
    flex-wrap: wrap;
    padding: 20px !important;
    height: auto !important;
    justify-content: start !important;
  }
  .MaintainSection .MaintainSectionContent .MaintainSection__title,
  .MaintainSection .MaintainSectionContent p.backsoon {
    font-size: 30px !important;
    margin-bottom: 12px !important;
  }
  .MaintainSection .MaintainSectionContent p.maintainword {
    font-size: 35px !important;
    margin-bottom: 15px !important;
  }
  .MaintainSection .MaintainImg img {
    width: 250px !important;
  }
  .MaintainSection .MaintainSectionContent p.maintainword::before,
  .MaintainSection .MaintainSectionContent p.maintainword::after {
    display: none !important;
  }
  .MaintainSection .MaintainSectionContent,
  .MaintainSection .MaintainImg {
    width: 100% !important;
    text-align: center !important;
  }
  .HpaySection img,
  .HpaySection canvas {
    width: 260px !important;
    height: 260px !important;
  }
  .HpaySection .HayQR img {
    width: 30px !important;
    height: 30px !important;
    right: 6px !important;
  }
  *:not(:empty) {
    transform-style: inherit !important;
  }
  .WithdrawPageSection .WithDrawSection.tabs-area {
    width: 100% !important;
  }
  .WithdrawGif {
    display: none;
  }
  .WithDrawSection.tabs-area ul li button {
    font-size: 11px !important;
  }
  .WithDrawBankDetails form {
    padding: 30px 0 0 !important;
  }

  .walletSection .wallet-leftpart,
  .walletSection .deposit_img {
    width: 100% !important;
  }
  .gatewaySection .tab-content .tab-pane.active .cards1 {
    width: 48% !important;
  }
  .gatewaySection .tab-content {
    padding: 20px !important;
  }
  .gatewaySection {
    max-width: 340px !important;
  }
  .walletSection .walletLeftSection {
    flex-wrap: wrap;
  }
  .footer .footer-section .footer-right-part {
    order: 1 !important;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
  }
  .footer .footer-section .footer-left-part {
    order: 2 !important;
    flex-wrap: wrap;
    width: 100% !important;
  }
  .footer .footer-section .footer-left-part .footer-menu-web {
    width: 100% !important;
    padding: 0 40px;
  }
  .footer .footer-section .footer-left-part .footer-menu-web div {
    width: 50% !important;
  }
  .mob-match-name {
    color: var(--whiteClr);
    text-align: left;
    font-weight: bold;
    padding-bottom: 12px;
  }
  .header .headerRight .btn_signin {
    width: 70px !important;
    padding: 6px 0 !important;
  }
  .header .headerRight .btn_signup {
    width: 75px !important;
  }
  .main .mainBanner .slick-slide img {
    max-height: calc(100vh - 80px) !important;
    object-fit: inherit !important;
  }
  .all-images li a:hover ~ .focus-border i::after,
  .all-images li a:hover ~ .focus-border i::before {
    height: 100% !important;
  }
  .all-images li a:hover ~ .focus-border::after,
  .all-images li a:hover ~ .focus-border::before {
    width: 100% !important;
  }
  .all-images li a:hover ~ .focus-border::before,
  .all-images li a:hover ~ .focus-border i::before {
    top: 0 !important;
    left: 0 !important;
  }
  .all-images li a:hover ~ .focus-border::after {
    bottom: 0 !important;
    right: 0 !important;
  }
  .all-images li a ~ .focus-border i::after {
    right: 0 !important;
    bottom: 0 !important;
  }
  .main .TopCategories .nav-tabs {
    margin-bottom: 0 !important;
  }
  .all-images ul li a p {
    height: 28px !important;
  }
  .main .TopCategories .Live-Casino .container-fluid {
    padding: 0 !important;
  }
  .all-images ul li img {
    height: auto !important;
  }
  .main .TopCategories .nav-tabs .nav-item button {
    white-space: nowrap !important;
    background-position: 3px 14px !important;
  }
  .main .payment-info.contact-pay-info {
    padding: 90px 15px !important;
  }
  .main .payment-info.contact-pay-info .contact-img {
    width: 290px;
    display: block;
  }
  .main .payment-info.contact-pay-info .contact-img img {
    width: 100% !important;
  }
  .main .payment-info .contact-social ul li {
    width: 290px !important;
    padding: 8px !important;
  }
  .main .payment-info.contact-pay-info {
    flex-wrap: wrap;
  }
  .main .payment-info .contact-social {
    margin-left: 0 !important;
  }
  .main .payment-info .contact-social ul {
    padding-left: 0;
  }
  .steps-canvas .form-steps .btn {
    margin-bottom: 15px;
  }
  .sessionmarket .game-points ul li .status-btns .info-block:nth-child(2) {
    display: flex !important;
    width: 50% !important;
  }
  .main .casino_sec.exchangeSection {
    padding: 0;
  }
  .main .casino_sec.exchangeSection .heading {
    padding: 1rem;
    margin: 0 !important;
    padding-bottom: 0 !important;
  }

  .footer {
    padding: 30px 1rem 80px !important;
  }
  .footer .footer-links ul li a {
    padding: 0 8px !important;
  }
  .footer .providers_img a {
    max-width: 100px !important;
  }
  .footer-links {
    margin-top: 15px;
  }
  .footer .providers_sec .providers_logo .logo_box img {
    max-width: 80px !important;
  }
  .header .headerRight .funds ul li a.deposit {
    margin: 0;
    width: 100% !important;
  }
  .sports_page_banner .slick-slider img {
    height: 120px !important;
  }
  .match_details_container .math_info .match_type {
    font-size: 10px !important;
    width: 260px !important;
    width: 260px !important;
    padding: 0 5px !important;
  }
  .page-details .game-points ul li.bookmaker-new .status-btns .info-block {
    display: none !important;
  }
  .page-details
    .game-points
    ul
    li.bookmaker-new
    .status-btns
    .info-block:nth-child(4),
  .page-details
    .game-points
    ul
    li.bookmaker-new
    .status-btns
    .info-block:nth-child(5) {
    display: flex !important;
    width: 50% !important;
  }
  .live_tv_box,
  .iframe-div {
    height: 260px !important;
  }
  .live_tv_box .iframe-div iframe {
    height: 260px !important;
  }
  .match_details_container
    .math_info
    .live-tv-mob
    button.live-tv-btn.collapse_container {
    background: #aaaaaa !important;
    border-radius: 5px !important;
    width: 30px;
    height: 30px;
  }
  .live_tv_box .no_data {
    font-size: 15px !important;
  }
  .match_details_container
    .math_info
    .live-tv-mob
    button.live-tv-btn.collapse_container:hover,
  .match_details_container
    .math_info
    .live-tv-mob
    button.live-tv-btn.collapse_container:focus {
    background: #333 !important;
  }
  .match_details_container .math_info .live-tv-mob button img {
    width: 20px !important;
  }
  .logo {
    max-width: 100px;
  }
}

@media screen and (max-width: 575.98px) {
  .button-primary.btn.btn-primary {
    padding: 6px 4px !important;
  }
  .footer .providers_img a {
    max-width: 85px !important;
    padding: 0 8px !important;
  }
  .sports_page
    .tab-pane
    .game-listing
    .match-info
    .match-name
    .breadcrumb
    .date_time_info,
  .custom-tabs
    .tab-pane
    .game-listing
    .match-info
    .match-name
    .breadcrumb
    .date_time_info {
    width: auto !important;
    height: auto !important;
    margin-right: 0 !important;
    padding: 0 !important;
  }
  .sports_page
    .tab-pane
    .game-listing
    .match-info
    .match-name
    .breadcrumb
    .date_time_info
    .date,
  .custom-tabs
    .tab-pane
    .game-listing
    .match-info
    .match-name
    .breadcrumb
    .date_time_info
    .date {
    font-size: 12px !important;
    height: 27px;
    padding: 6px;
  }

  .sports_page .tab-pane .game-listing .match-info .match-name .breadcrumb,
  .custom-tabs .tab-pane .game-listing .match-info .match-name .breadcrumb {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 480px) {
  .page-details .AllMarket_section .game-points ul li .team-name {
    display: block !important;
    width: calc(100% - 140px);
  }
}
/* .header {
  padding: 15px 0;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  background: var(--headClr);
  width: 100%;
}
.header .logo {
  max-width: 125px;
}
.navbar-toggler {
  display: none;
}
.header .navbar {
  padding: 0;
}
.header .navbar .navbar-collapse {
  background: var(--secondaryClr);
  margin-left: 15px;
  padding: 5px;
  border-radius: 22px;
}
.header .navbar .navbar-collapse .navbar-nav a {
  color: var(--whiteClr);
  padding: 0 12px;
}
.header .navbar .navbar-collapse .navbar-nav a svg,
.header .navbar .navbar-collapse .navbar-nav a img {
  fill: var(--whiteClr);
  width: 20px;
  height: 20px;
}
.header .navbar .navbar-collapse .navbar-nav a:hover {
  opacity: 0.8;
}
.header .headerRight {
  display: flex;
  align-items: center;
  fill: var(--whiteClr);
}
.header .headerRight .path-stroke {
  stroke: var(--whiteClr);
}
.header .headerRight .funds ul {
  background: var(--secondaryClr);
  border-radius: 25px;
  padding: 4px 12px;
  margin-right: 12px;
}
.header .headerRight .funds ul li {
  position: relative;
  margin: 0 10px;
}
.header .headerRight .funds ul li:first-child:after {
  border-right: 1px solid var(--whiteClr);
  content: "";
  position: absolute;
  height: 16px;
  top: 3px;
  right: -11px;
}
.header .headerRight .funds ul li a {
  color: var(--whiteClr);
}
.header .headerRight .funds ul li a {
  padding-left: 8px;
}
.header .headerRight ul {
 
 
}
.header .headerRight ul li {
  margin: 0 5px;
  position: relative;
}
.header .headerRight ul li .notification-count {
  width: 12px;
  height: 12px;
  background: var(--siteRedClr);
  color: var(--whiteClr);
  border-radius: 50%;
  position: absolute;
  right: -2px;
  top: -2px;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .headerRight .menu-btn {
  padding: 0;
  background: none;
  border: none;
}
.header .headerRight .menu-btn:hover,
.header .headerRight .menu-btn:focus {
  background: none !important;
  border-color: transparent !important;
}
.header .headerRight .menu-btn svg {
  width: 20px;
  height: auto;
  margin-left: 5px;
  fill: var(--primaryClr);
} */

* {
  padding: 0;
  margin: 0;
}

:root {
  --primaryClr: #169a85;
  --secondaryClr: #1c1d21;
  --whiteClr: #fff;
  --darkClr: #000;
  --grayClr: #a4a3a8;
  --lightGrayClr: #e4e4e4;
  --siteGreenClr: #508334;
  --siteYellowClr: #edbf2e;
}

/*=============== Common CSS ===============*/
html,
body {
  background: var(--whiteClr);
  font-size: 14px;
}
body:not(.mobile)::-webkit-scrollbar,
.nice-scrollbar::-webkit-scrollbar {
  width: 5px;
}
body:not(.mobile)::-webkit-scrollbar-thumb,
.nice-scrollbar::-webkit-scrollbar-thumb {
  background: #919595;
  border-radius: 0;
  border: solid 1px #919595;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
body:not(.mobile)::-webkit-scrollbar-track,
.nice-scrollbar::-webkit-scrollbar-track {
  background: #e4e4e4;
  margin: 2px 0;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
button:focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.form-control:focus,
.custom-select:focus {
  box-shadow: none;
}
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  transition: all 0.5s ease-in-out;
}
a:hover {
  text-decoration: none;
}
hr {
  border-color: var(--siteGreenClr);
}

/* betslip */
.betslip-wrapper {
  background: var(--headClr);
  display: flex;
  max-width: 350px;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  transition: 0.5s;
  right: 0;
  z-index: 999;
  box-shadow: 0 0px 8px rgba(255, 189, 0, 0.12);
}
.betslip-wrapper .betslip-head {
  border-top-left-radius: 0.5rem;
  box-shadow: 0 2px 18px rgb(0 0 0 / 25%);
  min-width: 100%;
  width: 100%;
  margin-left: 0 !important;
  right: 0;
  top: 0;
  z-index: 98;
}
.betslip-wrapper .betslip .betslip_close_btn {
  border-bottom: 1px solid var(--secondaryClr) !important;
  text-align: right !important;
}
.betslip-wrapper .closebtn {
  font-size: 18px;
  color: var(--whiteClr);
  margin-left: 0;
}
.betslipStart ul {
  border-bottom: 1px solid var(--secondaryClr);
  justify-content: center;
}
.betslipStart ul li a {
  background: transparent !important;
  color: var(--whiteClr) !important;
  font-weight: 600;
  text-align: center;
  border: 0 !important;
  padding: 0.5rem 1rem;
  font-size: 14px;
}
.betslipStart ul li a.active {
  color: var(--primaryClr) !important;
}
.betslipStart .betsTsbs ul.nav-tabs li {
  flex: 1;
}
.betslipStart .betslip-content {
  border-bottom: 0;
  padding: 1rem;
}
.betslipStart .betslip-content .match-name {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.betslipStart .betslip-content .match-name p {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0;
}
.betslipStart .betslip-content .match-name p .info {
  display: inline-block;
  margin-right: 0.75rem;
}
.betslipStart .betslip-content .match-name .betslip-close {
  background: #fff;
  border-radius: 50%;
  color: #080325;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  height: 16px;
  line-height: 13px;
  text-align: center;
  width: 16px;
}
.betslipStart .betslip-content .match-result {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
}
.betslipStart .betslip-content .match-result .team-name {
  color: var(--primaryClr);
  font-size: 0.9rem;
  font-weight: 600;
}
.betslipStart .betslip-content .match-result .team-name span {
  color: #fff;
}
.betslipStart .betslip-content .match-result .odds {
  background: var(--bodyClr);
  border: 1px solid var(--secondaryClr);
  border-radius: 25px;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  height: 100%;
  min-width: 60px;
  padding: 0.35rem 0.5rem;
  text-align: center;
}
.betslipStart .betslip-content .stack-win-value {
  align-items: center;
  display: flex;
  padding-top: 1rem;
}
.betslipStart .betslip-content .stack-win-value .stack,
.betslipStart .betslip-content .stack-win-value .winning {
  flex: 1 1;
}
.betslipStart .betslip-content .stack-win-value .stack .form-control {
  -webkit-animation: glowingGreen 1s infinite;
  animation: glowingGreen 1s infinite;
  background: #220a49;
  border: 1px solid var(--secondaryClr);
  color: var(--whiteClr);
  max-width: 120px;
}
.betslipStart .betslip-content .stack-win-value .winning p {
  color: #999;
  margin-bottom: 0;
  margin-left: 1rem;
}
.betslipStart .betslip-content .stack-win-value .winning p span {
  color: var(--whiteClr);
}
.betslipStart .betslip-content .auto-stack {
  padding: 1rem 0;
  border-bottom: 1px solid var(--secondaryClr);
}
.betslipStart .betslip-content .auto-stack ul {
  align-items: center;
  display: flex;
  border-bottom: 0;
}
.betslipStart .betslip-content .auto-stack ul li {
  flex: 1 1;
  margin-right: 0.5rem;
}
.betslipStart .betslip-content .auto-stack ul li .stack-btn {
  background: var(--secondaryClr);
  color: #fff;
  padding: 0.25rem 0.5rem;
  width: 100%;
}
.betslipStart .betslip-content .bet-now {
  margin-top: 15px;
}
.betslipStart .betslip-content .bet-now .place-btn {
  background: var(--primaryClr);
  color: var(--blackClr);
  font-size: 1.15rem;
  font-weight: 600;
  width: 100%;
}
.betslipStart .open-bets {
  padding-top: 1rem;
}
.betslipStart .open-bets form {
  padding-left: 1rem;
}
.betslipStart .open-bets form .form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.betslipStart .open-bets form .form-check .form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.betslipStart .open-bets form label {
  color: #fff;
  cursor: pointer;
}
.betslipStart .open-bets .my-bets .bet-heading {
  background: #1d1a3e;
  padding: 0.5rem;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.betslipStart .open-bets .my-bets .bet-heading li:first-child {
  font-size: 0.9rem;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.betslipStart .open-bets .my-bets .bet-heading li {
  color: #ddd;
  font-size: 0.85rem;
  text-align: right;
  width: 25%;
}
.betslipStart .open-bets .my-bets body {
  height: auto !important;
  max-height: calc(100vh - 118px);
  overflow-y: auto;
}
.betslipStart .open_bets {
  padding-top: 1rem;
}
.react-tel-input .country-list .country {
  text-align: left;
}
.react-tel-input .country-list .country span {
  color: #000;
}

@media only screen and (max-width: 767px) {
  .all-images ul {
    height: auto !important;
  }
  .betslip-content.inline_betslip .btn_group > div {
    width: calc(50% - 5px) !important;
    margin-right: 10px;
  }
  .middleContentSec .betslip_popup .betslip-content.inline_betslip .auto-stack {
    margin-bottom: 0 !important;
    padding: 5px !important;
  }
  .betslip-content.inline_betslip .btn_group {
    padding: 0 5px !important;
  }
  .betslip-content.inline_betslip .stack-win-value {
    margin-bottom: 0 !important;
  }
  .betslip_popup .betslip_content .backbet {
    margin-bottom: 5px !important;
  }
  .AccountPage form .register-field {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .login-page .main-login-form form .icon {
    top: 68% !important;
  }
  .page-details .game-points ul li.team-name span {
    width: calc(100% - 150px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #0134a9 !important;
  }
  .login-page {
    padding: 1rem !important;
    overflow-y: scroll;
    max-height: calc(100vh - 170px);
  }
  .login-page .heading1 span {
    font-size: 1.5rem !important;
  }
  .sports_page .nav .nav-item,
  .custom-tabs .nav .nav-item {
    margin: 0 !important;
  }
  .sports_page .nav .nav-item .nav-link,
  .custom-tabs .nav .nav-item .nav-link {
    padding: 6px 14px !important;
  }
  .footer .footer-content p {
    font-size: 13px !important;
    line-height: 20px !important;
    text-align: justify !important;
  }
  .header .headerRight .headicons {
    display: none !important;
  }
  .header .headerRight .balance-web ul li:last-child {
    display: block;
    border: 0;
  }
  .cashloader {
    width: 160px !important;
    height: 33px !important;
  }
  .cashloader p {
    height: 33px !important;
    font-size: 11px;
    border-radius: 25px 0 0 25px !important;
  }
  .cashloader p img {
    width: 24px !important;
    height: 24px !important;
  }
  .header .headerRight .balance-web ul {
    border-radius: 25px 0 0 25px !important;
    padding: 3px !important;
    height: 33px !important;
  }
  .header .headerRight .balance-web ul li {
    padding: 0 8px !important;
    font-size: 11px !important;
    line-height: 14px;
  }
  .headicons {
    display: none;
  }
  .header .headerRight button.deposit {
    background: var(--primaryClr) !important;
    color: var(--whiteClr) !important;
    font-size: 18px;
    font-weight: 600;
    height: 33px;
    display: flex;
    align-items: center;
    padding: 0 8px !important;
    border-radius: 0 25px 25px 0 !important;
    &::after {
      display: none;
    }
  }
  .betslip_popup .betslip_content .auto-stack ul li {
    background-color: initial !important;
    margin: 3px 5px !important;
    padding: 0 !important;
    width: 14% !important;
  }
  .footer .providers_sec::before {
    display: none;
  }
  footer.footer .providers_sec .providers_logo.payment_logo a {
    width: 65px !important;
  }
  .payment-heading {
    margin-top: 20px !important;
  }
  .footer .providers_sec .provider-mob-border {
    border-bottom: 1px solid #7e7e7e;
  }
  main .whtasapp-all-page {
    bottom: 145px !important;
    width: 100px !important;
  }
  .main .payment-info .btn {
    font-size: 12px;
  }
  .main .payment-info .btn .exch-funds-form {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 10px;
    font-weight: bold;
  }
}

@media only screen and (max-width: 575px) {
  .bet-liability-markets {
    display: none !important;
  }
  .betslip-content.inline_betslip .auto-stack ul li {
    margin: 1px 4px !important;
    width: 22% !important;
  }
}
@media only screen and (max-width: 420px) {
  .footer .footer-section .footer-left-part .footer-menu-web {
    padding: 0 10px !important;
  }
}

.Toastify {
  position: absolute;
  z-index: 999999999999;
}
.iframe-div {
  height: 100%;
  max-height: -webkit-fill-available;
}
.oddsChanged {
  background-color: rgb(252, 252, 78) !important;
}

.inplay-sportname {
  padding-left: 8px;
  padding-top: 8px;
  background: #444444;
  color: #000000;
  padding-bottom: 8px;
  margin-bottom: 0;
}

.no-live-events {
  padding-left: 16px;

  color: #000000;
}
